<div class="modal-header">
    <h4 class="modal-title">Barkod Tara</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group">
        {{barcodeValue}}
        <barcode-scanner-livestream
                type="code_128"
                (valueChanges)="onValueChanges($event)"
                (started)="(onStarted)"
        ></barcode-scanner-livestream>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Kapat</button>
    </div>
</div>
