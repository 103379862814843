import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ServiceConfig} from '../shared/ServiceConfig';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../auth/service/authentication.service';
import {_Menu} from '../shared/Menu';
import {Router} from '@angular/router';
import {EnvironmentService} from './environment.service';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    headers: any;
    menu = _Menu;

    constructor(private http: HttpClient, private toastrService: ToastrService,
                private router: Router, private authService: AuthenticationService,
                private envService: EnvironmentService) {
    }

    getService(item: ServiceConfig): Observable<[]> {
        return this.http.get<[]>(this.envService.getUrl() + item.url, {headers: this.headers, params: item.params});
    }

    deleteService(item: ServiceConfig): Observable<[]> {
        return this.http.delete<[]>(this.envService.getUrl()  + item.url, {headers: this.headers, params: item.params}).pipe(
            catchError(this.handleError));
    }

    postService(item: ServiceConfig): Observable<[]> {
        return this.http.post<[]>(this.envService.getUrl()  + item.url, item.data, {
            headers: this.headers,
            params: item.params,
        }).pipe(
            catchError(this.handleError));
    }

    getPermission(privilege) {
        return this.authService.isAdmin();
    }

    putService(item: ServiceConfig): Observable<[]> {
        return this.http.put<[]>(this.envService.getUrl()  + item.url, item.data, {params: item.params}).pipe(
            catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

    notiService(type, text?, title?) {
        if (type === 'success') {
            this.toastrService.success(text ? text : 'Değişiklikler kaydedildi', title ? title : 'Başarılı', {
                timeOut: 3000,
            });
        } else if (type === 'error') {
            this.toastrService.error(text ? text : 'Bir Hata oluştu', title ? title : 'Hata', {
                timeOut: 3000,
            });
        } else if (type === 'info') {
            this.toastrService.info(text, title, {
                timeOut: 4000,
            });
        }
    }

    getSelectedPage() {
        let selected;
        this.menu.forEach(m => {
            if (!m.submenu) {
                if ('/pages/' + m.url === this.router.url) {
                    selected = m;
                }
            } else {
                m.submenu.forEach(s => {
                    if ('/pages/' + s.url === this.router.url) {
                        selected = s;
                    }
                });
            }
        });
        return selected;
    }
}
