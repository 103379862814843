import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../services/general.service';
import {ServiceConfig} from '../../shared/ServiceConfig';
import {ConfirmComponent} from '../../components/confirm/confirm.component';
import {DeleteConfig} from '../../shared/DeleteConfig';
import {AddDataComponent} from '../../components/data/add-data/add-data.component';
import {EditDataComponent} from '../../components/data/edit-data/edit-data.component';

@Component({
    selector: 'app-esl-template',
    templateUrl: './data.component.html',
})
export class DataComponent implements OnInit, OnDestroy {
    search = '';
    items = [];
    loading = false;
    serviceSubs;

    constructor(private modalService: NgbModal, private generalService: GeneralService) {
    }

    ngOnInit(): void {
        this.get();
        this.getTemplates();
    }

    get() {
        this.loading = false;
        this.serviceSubs ? this.serviceSubs.unsubscribe() : null;
        this.serviceSubs = this.generalService.getService(new ServiceConfig('esl/template/data', {size: 9999})).subscribe((rs: any) => {
            this.items = rs.content;
            this.loading = false;
        });
    }

    trackByFn(index, item) {
        return item.id;
    }

    add() {
        const modalRef = this.modalService.open(AddDataComponent, {});
        modalRef.componentInstance.templates = this.templates;
        modalRef.componentInstance.added.subscribe(rs => {
            this.items.push(rs);
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
            modalRef.close();
        });
    }

    edit(item) {
        const modalRef = this.modalService.open(EditDataComponent, {});
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.templates = this.templates;
        modalRef.componentInstance.edited.subscribe(rs => {
            const index = this.items.findIndex(d => d.id === item.id);
            this.items[index] = rs;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
            modalRef.close();
        });
    }

    delete(item) {
        const modalRef = this.modalService.open(ConfirmComponent, {});
        modalRef.componentInstance.deleteConfig = new DeleteConfig('esl/template/data', item);
        modalRef.componentInstance.deleted.subscribe(d => {
            this.items = this.items.filter(itm => itm.id !== item.id);
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
        });
    }

    getTemplates() {
        this.templateSubs = this.generalService.getService(new ServiceConfig('esl/template')).subscribe(rs => {
            this.templates = rs;
        });
    }

    templateSubs;
    templates = [];

    public ngOnDestroy() {
        this.serviceSubs?.unsubscribe();
        this.templateSubs?.unsubscribe();

    }

}
