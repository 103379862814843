<div class="modal-header">
    <h4 class="modal-title">URL Ekle</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="addConfigForm" (ngSubmit)="add()">
        <div class="form-group">
            <label for="formGroupExampleInput">URL</label>
            <input formControlName="url" type="text" class="form-control" id="formGroupExampleInput"
                   placeholder="URL">
        </div>
        <div class="modal-footer">
            <button [disabled]="addConfigForm.invalid || loading" type="submit" class="btn btn-primary">
                <span *ngIf="loading">
                   <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Gönderiliyor...
                </span>
                <span *ngIf="!loading">URL Ekle</span>
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Kapat</button>
        </div>
    </form>
</div>
