import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
  selector: 'app-search-barcode',
  templateUrl: './search-barcode.component.html',
})
export class SearchBarcodeComponent implements OnInit {
  searched = new EventEmitter();

  searchBarcodeForm: FormGroup;
  loading = false;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }
  get f() {
    return this.searchBarcodeForm.controls;
  }
  ngOnInit(): void {
    this.searchBarcodeForm = this.formBuilder.group({
      barcode: ['', Validators.required],
    });
  }
  add() {
    this.loading = true;
    const service = new ServiceConfig('esl/open/barcode', {b: this.f.barcode.value});
    this.generalService.getService(service).subscribe((rs: any) => {
      this.loading = false;
      this.generalService.notiService('info', 'ESL Aranıyor...', '');
      this.searched.emit(rs.key);
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
