import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  serviceUrl;
  constructor(private http: HttpClient) {
  }
  load() {
   return this.http.get('assets/json/environment.json').toPromise().then((rs: any) => {
     this.serviceUrl = rs.serviceUrl;
   });
  }
  getUrl() {
    return this.serviceUrl;
  }
}
