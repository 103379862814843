import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
    selector: 'app-add-esl',
    templateUrl: './add-esl.component.html',
})
export class AddEslComponent implements OnInit {

    added = new EventEmitter();
    addEslForm: FormGroup;
    loading = false;
    @Input() locations;
    selectedLocation;
    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
    }
    get f() {
        return this.addEslForm.controls;
    }
    ngOnInit(): void {
        this.selectedLocation = this.locations[0].id;
        this.addEslForm = this.formBuilder.group({
            eslId: ['', Validators.required],
            eslNo: [0, Validators.required],
            locationId: [this.selectedLocation, Validators.required],
        });
    }
    add() {
        this.loading = true;
        const data = {eslId: this.f.eslId.value, eslNo: this.f.eslNo.value, eslType: 1, locationId: this.selectedLocation};
        this.generalService.postService(new ServiceConfig('esl', {}, data)).subscribe(rs => {
            this.loading = false;
            this.added.emit(rs);
            this.generalService.notiService('success');
        }, error => {
            this.loading = false;
            this.generalService.notiService('error');
        });
    }

}
