import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
    ngOnInit(): void {
        // tslint:disable-next-line:only-arrow-functions
/*        console.log = function() {
        };*/
    }

}

