import {NgModule} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {PagesComponent} from './pages.component';
import {PagesRoutingModule} from './pages-routing.module';
import {CommonModule} from '@angular/common';
import {ConfirmComponent} from '../components/confirm/confirm.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgSelectModule} from '@ng-select/ng-select';
import {SidebarModule} from 'ng-sidebar';
import {LocationComponent} from './location/location.component';
import {AddLocationComponent} from '../components/location/add-location/add-location.component';
import {EditLocationComponent} from '../components/location/edit-location/edit-location.component';
import { EslTemplateComponent } from './esl-template/esl-template.component';
import { UserComponent } from './user/user.component';
import {FilterPipe} from '../pipes/filter.pipe';
import {ActionModule} from '../components/action/action.module';
import { ConfigComponent } from './config/config.component';
import { LogComponent } from './log/log.component';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {DataComponent} from './data/data.component';


@NgModule({
    imports: [
        CommonModule,
        PagesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgSelectModule,
        NgbModule,
        SidebarModule.forRoot(),
        ActionModule,
        NgJsonEditorModule,
    ],
    declarations: [
        PagesComponent,
        HomeComponent,
        LocationComponent,
        AddLocationComponent,
        ConfirmComponent,
        EditLocationComponent,
        EslTemplateComponent,
        UserComponent,
        FilterPipe,
        ConfigComponent,
        LogComponent,
        DataComponent,
    ],
    exports: [FilterPipe],
})
export class PagesModule {
}
