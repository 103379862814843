<nav class="navbar navbar-dark bg-dark fixed-top ">
    <div style="max-width: 600px" class="d-block m-auto w-100">
        <h5 class="w-100 text-center text-white">Kayıtlar</h5>
    </div>
</nav>
<div style="margin-top: 70px" class="table-responsive">
    <table class="table table-striped">
        <thead>
        <tr>
            <th scope="col">ESL No</th>
            <th scope="col">Veri</th>
            <th scope="col">Tasarım</th>
            <th scope="col">Kullanıcı</th>
            <th scope="col">Tarih</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of items; index as i; trackBy: trackByFn">
            <td data-label="No">{{item.esl.eslNo}}</td>
            <td data-label="Veri" >{{item.dataModel?.key}}</td>
            <td data-label="Tasarım" >{{item.template.key}}</td>
            <td data-label="Kullanıcı" >{{item.user.username}}</td>
            <td data-label="Tarih" >{{item.createDateTime | date: 'dd-MM-yyy HH:mm'}}</td>
        </tr>
        </tbody>
    </table>
    <div *ngIf="loading" class="table-loading">
        <div class="loading-content">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
<div class="justify-content-center d-flex mb-4">
    <ngb-pagination (pageChange)="pageChanged($event)" *ngIf="!loading" [maxSize]="4" [(page)]="page"
                    [pageSize]="pageSize" [collectionSize]="total"></ngb-pagination>
</div>
