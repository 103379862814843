import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';
import {JsonEditorOptions} from 'ang-jsoneditor';

@Component({
  selector: 'app-send-esl-item',
  templateUrl: './send-esl-item.component.html',
})
export class SendEslItemComponent implements OnInit {

  added = new EventEmitter();
  sendEslForm: FormGroup;
  loading = false;
  @Input() templates;
  @Input() item;
  @Input() esls;
  selectedTemplate;
  selectedItem;
  hide = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }
  get f() {
    return this.sendEslForm.controls;
  }
  ngOnInit(): void {
    const tempId = localStorage.getItem('selectedTemplateId');
    this.selectedTemplate =  this.templates.find(f => tempId === f.id?.toString())?.id ?? this.templates[0].id;
    this.selectedItem = this.esls.find(esl => esl.id === this.item.id);
    this.sendEslForm = this.formBuilder.group({
      esl: [this.selectedItem.id, Validators.required],
      templateId: [this.selectedTemplate, Validators.required],
    });
  }
  changeEsl(id){
    this.selectedItem = this.esls.find(esl => esl.id === id);
    this.hide = true;
    setTimeout(() => {
      this.hide = false;
    });
  }
  add() {
    this.loading = true;
    const data = {eslId: this.selectedItem.id, templateDataId: this.selectedTemplate};
    this.generalService.postService(new ServiceConfig('esl/bind/v2', {}, data)).subscribe((rs: any) => {
      this.loading = false;
      this.added.emit(rs.esl);
      localStorage.setItem('selectedTemplateId', this.selectedTemplate?.toString());
      this.generalService.notiService('success');
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
