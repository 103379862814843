import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../services/general.service';
import {ServiceConfig} from '../../shared/ServiceConfig';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
})
export class LogComponent implements OnInit, OnDestroy {

  items = [];
  loading = false;
  serviceSubs;
  search = '';
  page = 1;
  pageSize = 10;
  total = 0;
  constructor(private modalService: NgbModal, private generalService: GeneralService) {
  }

  ngOnInit(): void {
    this.get();
  }
  pageChanged(item) {
    this.page = item;
    this.get();
  }

  get() {
    this.loading = false;
    this.serviceSubs ? this.serviceSubs.unsubscribe() : null;
    const service = new ServiceConfig('log', {page: this.page - 1, size: this.pageSize, sort: 'id,desc'});
    this.serviceSubs = this.generalService.getService(service).subscribe((rs: any) => {
      this.items = rs.content;
      this.total = rs.totalElements;
      this.loading = false;
    });
  }

  trackByFn(index, item) {
    return item.id;
  }

  public ngOnDestroy() {
    this.serviceSubs?.unsubscribe();
  }

}
