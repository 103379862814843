import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {registerLocaleData} from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import {AppRoutingModule} from './app-routing.module';
import {GeneralService} from './services/general.service';
registerLocaleData(localeTr);
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import {ToastrModule, ToastrService} from 'ngx-toastr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {AuthGuard, JwtInterceptor, LogoutGuard} from './_helpers';
import {AuthenticationService} from './auth/service/authentication.service';
import { AddEslComponent } from './components/esl/add-esl/add-esl.component';
import { EditEslComponent } from './components/esl/edit-esl/edit-esl.component';
import { AddEslTemplateComponent } from './components/esl-template/add-esl-template/add-esl-template.component';
import { EditEslTemplateComponent } from './components/esl-template/edit-esl-template/edit-esl-template.component';
import { AddUserComponent } from './components/user/add-user/add-user.component';
import { EditUserComponent } from './components/user/edit-user/edit-user.component';
import { AddConfigComponent } from './components/config/add-config/add-config.component';
import { EditConfigComponent } from './components/config/edit-config/edit-config.component';
import { SendEslItemComponent } from './components/esl/send-esl-item/send-esl-item.component';
import { SearchBarcodeComponent } from './components/esl/search-barcode/search-barcode.component';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {AddDataComponent} from './components/data/add-data/add-data.component';
import {EditDataComponent} from './components/data/edit-data/edit-data.component';
import {EnvironmentService} from './services/environment.service';
import {BarcodeScannerLivestreamModule} from 'ngx-barcode-scanner';
import {ReadBarcodeComponent} from './components/esl/read-barcode/read-barcode.component';
import {SendEslFreeItemComponent} from './components/esl/send-esl-free-item/send-esl-free-item.component';
import {PagesModule} from './pages/pages.module';

@NgModule({
    imports: [
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        NgbModule,
        NgSelectModule,
        NgJsonEditorModule,
        BarcodeScannerLivestreamModule,
        PagesModule,
        FormsModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        AddEslComponent,
        EditEslComponent,
        AddEslTemplateComponent,
        EditEslTemplateComponent,
        AddUserComponent,
        EditUserComponent,
        AddConfigComponent,
        EditConfigComponent,
        SendEslItemComponent,
        SendEslFreeItemComponent,
        SearchBarcodeComponent,
        ReadBarcodeComponent,
        AddDataComponent,
        EditDataComponent
    ],
    exports: [BarcodeScannerLivestreamModule],
    providers: [
        {provide: LOCALE_ID, useValue: 'tr-TR'}, GeneralService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: (ds: EnvironmentService) => () => {
                return ds.load();
            },
            deps: [EnvironmentService],
            multi: true,
        },
        /*
                {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        */
        AuthGuard, LogoutGuard, ToastrService, AuthenticationService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
