import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
  selector: 'app-edit-esl',
  templateUrl: './edit-esl.component.html',
})
export class EditEslComponent implements OnInit {

  edited = new EventEmitter();
  editEslForm: FormGroup;
  loading = false;
  @Input() item;
  @Input() locations;
  selectedLocation;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }
  get f() {
    return this.editEslForm.controls;
  }
  ngOnInit(): void {
    this.selectedLocation = this.item.location?.id;
    this.editEslForm = this.formBuilder.group({
      eslId: [this.item.eslId, Validators.required],
      eslNo: [this.item.eslNo, Validators.required],
      locationId: [this.selectedLocation, Validators.required],
    });
  }
  edit() {
    this.loading = true;
    const data = {eslId: this.f.eslId.value, eslNo: this.f.eslNo.value, eslType: 1, locationId: this.selectedLocation};
    this.generalService.putService(new ServiceConfig('esl', {id: this.item.id}, data)).subscribe(rs => {
      this.loading = false;
      this.edited.emit(rs);
      this.generalService.notiService('success');
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
