import {AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {_Menu} from '../shared/Menu';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../auth/service/authentication.service';
import {GeneralService} from '../services/general.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-pages',
    templateUrl: 'pages.component.html',
})
export class PagesComponent implements OnInit, AfterContentChecked {
    // tslint:disable-next-line:variable-name
    opened = true;
    activeUrl;
    menuItems = _Menu;
    user;
    permissionPageList = [];
    isAdmin;

    constructor(private router: Router, private authService: AuthenticationService, private cdref: ChangeDetectorRef,
                private http: HttpClient, private generalService: GeneralService) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.isAdmin = this.authService.isAdmin();
                this.activeUrl = val.url;
                this.menuItems.forEach(m => {
                    m.submenu?.forEach(s => '/pages/' + s.url === val.url ? m.active = true : null);
                    if (!this.isAdmin) {
                        this.routePermission();
                    }
                });
            }
        });
    }

    routePermission() {
        const selectedPage = this.generalService.getSelectedPage();
        if (selectedPage && !selectedPage.show) {
            this.router.navigate(['/pages/home']);
        }
    }

    ngAfterContentChecked() {

        this.cdref.detectChanges();

    }

    toggleSidebar() {
        this.opened = !this.opened;
    }

    options = {multi: false};
    config;

    findInMenu(menu) {
        return menu.includes(m => m.url === this.activeUrl);
    }

    ngOnInit() {
        this.getUser();
        this.config = this.mergeConfig(this.options);
    }

    logout() {
        this.authService.logout();
        setTimeout(() => {
            window.location.reload();
        });
    }

    getUser() {
        this.authService.user.subscribe(user => {
            this.user = user;
        });
    }

    mergeConfig(options) {
        const config = {
            multi: true
        };
        return {...config, ...options};
    }

    toggle(index: number) {
        if (!this.config.multi) {
            this.menuItems.filter(
                (menu, i) => i !== index && menu.active
            ).forEach(menu => menu.active = !menu.active);
        }
        this.menuItems[index].active = !this.menuItems[index].active;
    }

}

