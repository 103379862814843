import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../services/general.service';
import {ServiceConfig} from '../../shared/ServiceConfig';
import {ConfirmComponent} from '../../components/confirm/confirm.component';
import {DeleteConfig} from '../../shared/DeleteConfig';
import {EditConfigComponent} from '../../components/config/edit-config/edit-config.component';
import {AddConfigComponent} from '../../components/config/add-config/add-config.component';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, OnDestroy {

  items = [];
  loading = false;
  serviceSubs;
  search = '';
  constructor(private modalService: NgbModal, private generalService: GeneralService) {
  }

  ngOnInit(): void {
    this.get();
  }
  get() {
    this.loading = false;
    this.serviceSubs ? this.serviceSubs.unsubscribe() : null;
    this.serviceSubs = this.generalService.getService(new ServiceConfig('conf')).subscribe(rs => {
      this.items = rs;
      this.loading = false;
    });
  }

  trackByFn(index, item) {
    return item.id;
  }

  add() {
    const modalRef = this.modalService.open(AddConfigComponent);
    modalRef.componentInstance.added.subscribe(rs => {
      this.items.unshift(rs);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      });
      modalRef.close();
    });
  }

  edit(item) {
    const modalRef = this.modalService.open(EditConfigComponent, {});
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.edited.subscribe(rs => {
      const index = this.items.findIndex(d => d.id === item.id);
      this.items[index] = rs;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      });
      modalRef.close();
    });
  }

  delete(item) {
    const modalRef = this.modalService.open(ConfirmComponent, {});
    modalRef.componentInstance.deleteConfig = new DeleteConfig('conf', item);
    modalRef.componentInstance.deleted.subscribe(d => {
      this.items = this.items.filter(itm => itm.id !== item.id);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      });
    });
  }

  public ngOnDestroy() {
    this.serviceSubs?.unsubscribe();
  }

}
