import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {NgModule} from '@angular/core';
import {PagesComponent} from './pages.component';
import {LocationComponent} from './location/location.component';
import {EslTemplateComponent} from './esl-template/esl-template.component';
import {UserComponent} from './user/user.component';
import {ConfigComponent} from './config/config.component';
import {LogComponent} from "./log/log.component";
import {DataComponent} from './data/data.component';


const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'home',
      component: HomeComponent
    },
    {
      path: 'data',
      component: DataComponent
    },
    {
      path: 'log',
      component: LogComponent
    },
    {
      path: 'location',
      component: LocationComponent
    },
    {
      path: 'esl-template',
      component: EslTemplateComponent
    },
    {
      path: 'user',
      component: UserComponent
    },
    {
      path: 'config',
      component: ConfigComponent
    },
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: '**', redirectTo: 'home'},
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
