import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
})
export class AddUserComponent implements OnInit {

  added = new EventEmitter();
  addUserForm: FormGroup;
  loading = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }

  get f() {
    return this.addUserForm.controls;
  }

  ngOnInit(): void {
    this.addUserForm = this.formBuilder.group({
      username: ['', Validators.required],
      name: ['', Validators.required],
      password: ['', Validators.required],

    });
  }

  add() {
    this.loading = true;
    const params = {n: this.f.name.value, un: this.f.username.value, p: this.f.password.value};
    this.generalService.postService(new ServiceConfig('user', params)).subscribe(rs => {
      this.loading = false;
      this.added.emit(rs);
      this.generalService.notiService('success');
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
