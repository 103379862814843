import {NgModule} from '@angular/core';
import {ActionComponent} from './action.component';
import {CommonModule} from '@angular/common';


@NgModule({
    imports: [CommonModule],
    declarations: [ActionComponent],
    exports: [ActionComponent]
})
export class ActionModule {
}
