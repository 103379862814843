import {Component, EventEmitter, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
    selector: 'app-add-location',
    templateUrl: './add-location.component.html',
})
export class AddLocationComponent implements OnInit {

    added = new EventEmitter();
    addLocationForm: FormGroup;
    loading = false;

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
    }

    get f() {
        return this.addLocationForm.controls;
    }

    ngOnInit(): void {
        this.addLocationForm = this.formBuilder.group({
            name: ['', Validators.required],
        });
    }

    add() {
        this.loading = true;
        this.generalService.postService(new ServiceConfig('location', {n: this.f.name.value})).subscribe(rs => {
            this.loading = false;
            this.added.emit(rs);
            this.generalService.notiService('success');
        }, error => {
            this.loading = false;
            this.generalService.notiService('error');
        });
    }
}
