import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteConfig} from '../../shared/DeleteConfig';
import {GeneralService} from '../../services/general.service';
import {ServiceConfig} from '../../shared/ServiceConfig';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {
    @Input() deleteConfig: DeleteConfig;
    deleted = new EventEmitter();
    constructor(public modal: NgbActiveModal, private generalService: GeneralService) {
    }

    ngOnInit(): void {
    }

    delete() {
        this.generalService.deleteService(new ServiceConfig(this.deleteConfig.url, {id: this.deleteConfig.item.id})).subscribe(d => {
            this.generalService.notiService('success');
            this.deleted.emit(d);
        }, error => {
            this.generalService.notiService('error');
        });
        this.modal.close();
    }

}
