import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../services/general.service';
import {ServiceConfig} from '../../shared/ServiceConfig';
import {AddLocationComponent} from '../../components/location/add-location/add-location.component';
import {EditLocationComponent} from '../../components/location/edit-location/edit-location.component';
import {ConfirmComponent} from '../../components/confirm/confirm.component';
import {DeleteConfig} from '../../shared/DeleteConfig';
import {AddEslTemplateComponent} from '../../components/esl-template/add-esl-template/add-esl-template.component';
import {EditEslTemplateComponent} from '../../components/esl-template/edit-esl-template/edit-esl-template.component';

@Component({
    selector: 'app-esl-template',
    templateUrl: './esl-template.component.html',
})
export class EslTemplateComponent implements OnInit, OnDestroy {
    search = '';
    items = [];
    loading = false;
    serviceSubs;

    constructor(private modalService: NgbModal, private generalService: GeneralService) {
    }

    ngOnInit(): void {
        this.get();
    }

    get() {
        this.loading = false;
        this.serviceSubs ? this.serviceSubs.unsubscribe() : null;
        this.serviceSubs = this.generalService.getService(new ServiceConfig('esl/template')).subscribe(rs => {
            this.items = rs;
            this.loading = false;
        });
    }

    trackByFn(index, item) {
        return item.id;
    }

    add() {
        const modalRef = this.modalService.open(AddEslTemplateComponent);
        modalRef.componentInstance.added.subscribe(rs => {
            this.items.push(rs);
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
            modalRef.close();
        });
    }

    edit(item) {
        const modalRef = this.modalService.open(EditEslTemplateComponent, {});
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.edited.subscribe(rs => {
            const index = this.items.findIndex(d => d.id === item.id);
            this.items[index] = rs;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
            modalRef.close();
        });
    }

    delete(item) {
        const modalRef = this.modalService.open(ConfirmComponent, {});
        modalRef.componentInstance.deleteConfig = new DeleteConfig('esl/template', item);
        modalRef.componentInstance.deleted.subscribe(d => {
            this.items = this.items.filter(itm => itm.id !== item.id);
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
        });
    }

    public ngOnDestroy() {
        this.serviceSubs?.unsubscribe();
    }

}
