import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
})
export class EditUserComponent implements OnInit {

  edited = new EventEmitter();
  editUserForm: FormGroup;
  loading = false;
  @Input() item;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }

  get f() {
    return this.editUserForm.controls;
  }

  ngOnInit(): void {
    this.editUserForm = this.formBuilder.group({
      username: [this.item.username, Validators.required],
      name: [this.item.fullName, Validators.required],
    });
  }

  edit() {
    this.loading = true;
    const params = {n: this.f.name.value, un: this.f.username.value, id: this.item.id};
    this.generalService.putService(new ServiceConfig('user', params)).subscribe(rs => {
      this.loading = false;
      this.edited.emit(rs);
      this.generalService.notiService('success');
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
