<ng-sidebar-container>
    <ng-sidebar [autoCollapseWidth]="500" mode="push" [(opened)]="opened">
        <div class="profile row m-0">
            <div class="col-4 p-0">
                <img width="80px" src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                     class="rounded-circle img-thumbnail m-2" alt="avatar">
            </div>
            <div class="col-8 p-0">
                <h4 class="text-center text-light pt-1 mb-1">{{user?.name}}</h4>
<!--
                <div class="text-center text-light pb-2">{{user?.email}}</div>
-->
                <a class="logout-button" (click)="logout()">Çıkış Yap</a>
            </div>
        </div>
        <ul id="accordion" class="accordion list-group">

            <a [routerLink]="menu.url" *ngFor="let menu of menuItems; let i = index">
                <li *ngIf="menu.show || isAdmin" [ngClass]="{'active': activeUrl === '/pages/'+menu.url && !menu.submenu}"
                    class="list-group-item pl-5">
                    <div (click)="toggle(i)">
                        <i [class]="menu.iconClass"></i>
                        {{ menu.key }}
                        <i *ngIf="menu.submenu" class="fa fa-chevron-down"></i>
                    </div>
                    <ul class="submenu" #submenu
                        [style.height.px]="menu.active ? submenu.scrollHeight : 0">
                        <div *ngFor="let submenu of menu.submenu; let j = index">
                            <a *ngIf="submenu.show" [ngClass]="{'active': activeUrl === '/pages/'+submenu.url, 'border-0': j == 0}"
                                [routerLink]="submenu.url" >
                                <li >{{ submenu.key }}</li>
                            </a>
                        </div>

                    </ul>
                </li>
            </a>
        </ul>
        <div class="text-center position-absolute w-100 p-2" style="bottom: 0">
            <a style="color: teal" target="_blank" href="https://digyglobal.com">Powered by Digyglobal</a>
        </div>
    </ng-sidebar>
    <div ng-sidebar-content>
        <router-outlet></router-outlet>
        <button (click)="toggleSidebar()" class="navbar-toggler-btn btn btn-outline-light">
            <span class="fa fa-bars"></span>
        </button>
    </div>
</ng-sidebar-container>
<!--<div *ngIf="!user" id="overlay">
    <div id="progstat"><img src="assets/img/loading.svg"></div>
</div>-->
<style>
    ::ng-deep html, ::ng-deep body {
        height: 100%;
        background: white !important;
    }
    #overlay {
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: black;
        transition: 1s 0.4s;
    }

    #progress {
        height: 1px;
        background-color: black;
        position: absolute;
        width: 0;
        top: 40%;
    }

    #progstat {
        font-size: 0.7em;
        letter-spacing: 3px;
        position: absolute;
        top: 40%;
        margin-top: -40px;
        width: 100%;
        text-align: center;
        color: #fff;
    }
</style>
