import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../service/authentication.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  error = false;
  private authServiceV2: AuthenticationService;


  constructor(private formBuilder: FormBuilder, authServiceV2: AuthenticationService,  private router: Router) {
    this.authServiceV2 = authServiceV2;
  }

  // tslint:disable-next-line:typedef
  get f() {
    return this.loginForm.controls;
  }
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
  login(): void {
    this.error = false;
    let password: any;
    let username: any;
    username = this.f.username.value;
    password = this.f.password.value;
    let authObs: Observable<any>;
    this.loading = true;
    authObs = this.authServiceV2.login(username, password);
    authObs.subscribe(r => {
      this.router.navigate(['pages/home']);
      this.loading = false;
    }, error => {
      this.error = true;
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

}
