
export class ServiceConfig {
    url: string;
    params?: any;
    header?: any;
    data?: any;
    constructor(url, params?, data?, header?) {
        this.url = url;
        this.params = params;
        this.header = header;
        this.data = data;
    }
}

