import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], key: string, searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toString().toLocaleLowerCase('tr');

    return items.filter(it => {
      const keys = key.split('.');
      let selected = it;
      keys.forEach(k => {
        selected = selected[k];
      });
      return selected?.toString().toLocaleLowerCase('tr').includes(searchText.toString());
    });
  }

}
