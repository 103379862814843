<div class="modal-header">
    <h4 class="modal-title">{{item.eslId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="editEslForm" (ngSubmit)="edit()">
        <div class="form-group">
            <label for="formGroupExampleInput">Barkod</label>
            <input formControlName="eslId" type="text" class="form-control" id="formGroupExampleInput"
                   placeholder="Barkod Numarası">
        </div>
        <div class="form-group">
            <label for="formGroupExampleInput">Esl No</label>
            <input formControlName="eslNo" type="number" class="form-control" id="formGroupExampleInput2"
                   placeholder="Esl Numarası">
        </div>
        <div class="form-group">
            <label>Konum</label>
            <ng-select formControlName="locationId" [(ngModel)]="selectedLocation">
                <ng-option *ngFor="let location of locations" [value]="location.id">{{location.name}}</ng-option>
            </ng-select>
        </div>
        <div class="modal-footer">
            <button [disabled]="editEslForm.invalid || loading" type="submit" class="btn btn-primary">
                 <span *ngIf="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Gönderiliyor...
                </span>
                <span *ngIf="!loading">Değişiklikleri Kaydet</span>
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Kapat</button>
        </div>
    </form>
</div>

