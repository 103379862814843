import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
})
export class EditLocationComponent implements OnInit {

  @Input() item;
  edited = new EventEmitter();
  editLocationForm: FormGroup;
  loading = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }
  get f() {
    return this.editLocationForm.controls;
  }

  ngOnInit(): void {
    this.editLocationForm = this.formBuilder.group({
      name: [this.item.name, Validators.required],
    });
  }

  edit() {
    this.loading = true;
    this.generalService.putService(new ServiceConfig('location', {n: this.f.name.value, id: this.item.id})).subscribe(rs => {
      this.generalService.notiService('success');
      this.loading = false;
      this.edited.emit(rs);
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
