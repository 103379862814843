import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
})
export class EditConfigComponent implements OnInit {

  @Input() item;
  edited = new EventEmitter();
  editConfigForm: FormGroup;
  loading = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }
  get f() {
    return this.editConfigForm.controls;
  }

  ngOnInit(): void {
    this.editConfigForm = this.formBuilder.group({
      url: [this.item.workingUrl, Validators.required],
    });
  }

  edit() {
    this.loading = true;
    this.generalService.putService(new ServiceConfig('conf', {url: this.f.url.value, id: this.item.id})).subscribe(rs => {
      this.generalService.notiService('success');
      this.loading = false;
      this.edited.emit(rs);
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
