import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';

@Component({
  selector: 'app-add-config',
  templateUrl: './add-config.component.html',
})
export class AddConfigComponent implements OnInit {


  added = new EventEmitter();
  addConfigForm: FormGroup;
  loading = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }

  get f() {
    return this.addConfigForm.controls;
  }

  ngOnInit(): void {
    this.addConfigForm = this.formBuilder.group({
      url: ['', Validators.required],
    });
  }

  add() {
    this.loading = true;
    this.generalService.postService(new ServiceConfig('conf', {url: this.f.url.value})).subscribe(rs => {
      this.loading = false;
      this.added.emit(rs);
      this.generalService.notiService('success');
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
