export class DeleteConfig {
    url: string;
    total: number;
    loading: boolean;
    items: any;
    item: any;
    constructor(url, item?, items?, total?, loading?) {
        this.url = url;
        this.item = item;
        this.items = items;
        this.total = total;
        this.loading = loading;

    }
}
