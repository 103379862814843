<div class="modal-header">
    <h4 class="modal-title">{{item.key}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="editDataForm" (ngSubmit)="edit()">
        <div class="form-group">
            <label for="formGroupExampleInput2">Veri Adı</label>
            <input formControlName="key" type="text" class="form-control" id="formGroupExampleInput2" placeholder="Veri Adı">
        </div>
        <div class="form-group">
            <label>Tasarım</label>
            <ng-select *ngIf="templates.length > 0" bindLabel="key" formControlName="template">
                <ng-option *ngFor="let temp of templates" [value]="temp">{{temp?.key}}</ng-option>
            </ng-select>
        </div>
        <div class="form-group">
            <label>Veri</label>
            <json-editor [options]="editorOptions" formControlName="data"></json-editor>
        </div>
        <div class="modal-footer">
            <button [disabled]="editDataForm.invalid || loading" type="submit" class="btn btn-primary">
                <span *ngIf="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Gönderiliyor...
                </span>
                <span *ngIf="!loading">Değişiklikleri Kaydet</span>
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Kapat</button>
        </div>
    </form>
</div>
