<div class="modal-header">
    <h4 class="modal-title">ESL Tasarım Ekle</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="addTemplateForm" (ngSubmit)="add()">
        <div class="form-group">
            <label for="formGroupExampleInput">Anahtar Kelime</label>
            <input formControlName="name" type="text" class="form-control" id="formGroupExampleInput" placeholder="Anahtar Kelime (Name)">
        </div>
        <div class="form-group">
            <label for="formGroupExampleInput2">Tasarım Adı</label>
            <input formControlName="key" type="text" class="form-control" id="formGroupExampleInput2" placeholder="Tasarım Adı (Key)">
        </div>
        <div class="form-group">
            <label>Şema</label>
            <json-editor [options]="editorOptions" formControlName="schemaModel"></json-editor>
        </div>
        <div class="modal-footer">
            <button [disabled]="addTemplateForm.invalid || loading" type="submit" class="btn btn-primary">
                <span *ngIf="loading">
                   <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Gönderiliyor...
                </span>
                <span *ngIf="!loading">Tasarım Ekle</span>
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Kapat</button>
        </div>
    </form>
</div>
