import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../services/general.service';
import {ServiceConfig} from '../../shared/ServiceConfig';
import {ConfirmComponent} from '../../components/confirm/confirm.component';
import {DeleteConfig} from '../../shared/DeleteConfig';
import {AddEslComponent} from '../../components/esl/add-esl/add-esl.component';
import {EditEslComponent} from '../../components/esl/edit-esl/edit-esl.component';
import {SendEslItemComponent} from '../../components/esl/send-esl-item/send-esl-item.component';
import {SearchBarcodeComponent} from '../../components/esl/search-barcode/search-barcode.component';
import {ReadBarcodeComponent} from '../../components/esl/read-barcode/read-barcode.component';
import {SendEslFreeItemComponent} from '../../components/esl/send-esl-free-item/send-esl-free-item.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
    items = [];
    loading = false;
    locations;
    templates;
    templateData;
    serviceSubs;
    locationSubs;
    templateSubs;
    templateDataSubs;
    filter = {m: '', n: ''};
    page = 1;
    pageSize = 20;
    total = 0;

    constructor(private modalService: NgbModal, private generalService: GeneralService) {
    }

    ngOnInit(): void {
        this.get();
        this.getEsls();
        this.getLocations();
        this.getTemplates();
        this.getTemplateData();

    }

    get() {
        this.loading = true;
        this.serviceSubs?.unsubscribe();
        const params = {id: -1, size: this.pageSize, page: this.page - 1, m: this.filter.m, n: this.filter.n};
        const service = new ServiceConfig('esl/v2', params);
        this.serviceSubs = this.generalService.getService(service).subscribe((rs: any) => {
            this.items = rs.content;
            this.total = rs.totalElements;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    esls = [];
    eslSubs;

    getEsls() {
        this.eslSubs = this.generalService.getService(new ServiceConfig('esl/all')).subscribe(rs => {
            this.esls = rs;
        });
    }

    getLocations() {
        this.locationSubs = this.generalService.getService(new ServiceConfig('location')).subscribe(rs => {
            this.locations = rs;
        });
    }

    getTemplateData() {
        this.templateSubs?.unsubscribe();
        this.templateSubs = this.generalService.getService(new ServiceConfig('esl/template/data', {size: 9999})).subscribe((rs: any) => {
            this.templateData = rs.content;
        });
    }
    getTemplates() {
        this.templateDataSubs?.unsubscribe();
        this.templateDataSubs = this.generalService.getService(new ServiceConfig('esl/template', {size: 9999})).subscribe((rs: any) => {
            this.templates = rs;
        });
    }
    pageChanged(item) {
        this.page = item;
        this.get();
    }

    trackByFn(index, item) {
        return item.id;
    }

    add() {
        const modalRef = this.modalService.open(AddEslComponent, {});
        modalRef.componentInstance.locations = this.locations;
        modalRef.componentInstance.added.subscribe(rs => {
            this.items.unshift(rs);
            this.esls.unshift(rs);
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
            modalRef.close();
        });
    }

    edit(item) {
        const modalRef = this.modalService.open(EditEslComponent, {});
        modalRef.componentInstance.locations = this.locations;
        modalRef.componentInstance.item = item;

        modalRef.componentInstance.edited.subscribe(rs => {
            const index = this.items.findIndex(d => d.id === item.id);
            this.items[index] = rs;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
            modalRef.close();
        });
    }

    sendSubs;

    send(item) {
        this.sendSubs?.unsubscribe();
        const modalRef = this.modalService.open(SendEslItemComponent, {});
        modalRef.componentInstance.templates = this.templateData;
        modalRef.componentInstance.esls = this.esls;
        modalRef.componentInstance.item = {...item};
        this.sendSubs = modalRef.componentInstance.added.subscribe(rs => {
            const index = this.items.findIndex(d => d.id === rs.id);
            this.items[index] = rs;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
        });
    }

    sendFree(item) {
        this.sendSubs?.unsubscribe();
        const modalRef = this.modalService.open(SendEslFreeItemComponent, {});
        modalRef.componentInstance.templates = this.templates;
        modalRef.componentInstance.esls = this.esls;
        modalRef.componentInstance.item = {...item};
        this.sendSubs = modalRef.componentInstance.added.subscribe(rs => {
            const index = this.items.findIndex(d => d.id === rs.id);
            this.items[index] = rs;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
        });
    }

    delete(item) {
        const modalRef = this.modalService.open(ConfirmComponent, {});
        modalRef.componentInstance.deleteConfig = new DeleteConfig('esl', item);
        modalRef.componentInstance.deleted.subscribe(d => {
            this.items = this.items.filter(itm => itm.id !== item.id);
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            });
        });
    }

    searchBarcode() {
        const modalRef = this.modalService.open(SearchBarcodeComponent, {});
        modalRef.componentInstance.searched.subscribe(key => {
            this.filter.m = key;
            this.filterChanged();
            modalRef.close();
        });
    }

    readBarcode() {
        const modalRef = this.modalService.open(ReadBarcodeComponent, {});
        modalRef.componentInstance.searched.subscribe(key => {
            this.filter.m = key;
            this.filterChanged();
            modalRef.close();
        });
    }

    timeout;

    filterChanged() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.pageChanged(1);
        }, 400);
    }

    public ngOnDestroy() {
        this.locationSubs?.unsubscribe();
        this.serviceSubs?.unsubscribe();
        this.templateSubs?.unsubscribe();
        this.templateDataSubs?.unsubscribe();
        this.eslSubs?.unsubscribe();
        this.sendSubs?.unsubscribe();

    }

}
