import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {_Menu} from '../../shared/Menu';
import {GeneralService} from '../../services/general.service';
import {AuthenticationService} from '../../auth/service/authentication.service';

@Component({
    selector: 'app-action',
    templateUrl: './action.component.html',
})
export class ActionComponent implements OnInit {
    @Input() type;
    @Input() download;
    @Input() name;
    @Output() edit = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() add = new EventEmitter();

    menu = _Menu;
    hideEdit = false;
    hideDelete = false;
    hideAdd = false;
    hideDownload = false;
    isAdmin;

    constructor(private router: Router, private generalService: GeneralService, private authService: AuthenticationService) {
    }

    ngOnInit(): void {
        let selected;
        selected = this.generalService.getSelectedPage();
        if (selected?.actions) {
            this.generalService.getPermission(selected?.actions.edit) ? this.hideEdit = false : this.hideEdit = true;
            this.generalService.getPermission(selected?.actions.delete) ? this.hideDelete = false : this.hideDelete = true;
            if (this.type === 'add') {
                this.generalService.getPermission(selected?.actions.add) ? this.hideAdd = false : this.hideAdd = true;
            }
            if (this.download) {
                this.generalService.getPermission(selected?.actions.download) ? this.hideDownload = false : this.hideDownload = true;
            }
        }
    }

    editClick() {
        this.edit.emit();
    }

    addClick() {
        this.add.emit();
    }

    deleteClick() {
        this.delete.emit();
    }
}
