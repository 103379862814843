import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';
import {JsonEditorOptions} from 'ang-jsoneditor';

@Component({
  selector: 'app-add-data',
  templateUrl: './add-data.component.html',
})
export class AddDataComponent implements OnInit {

  added = new EventEmitter();
  addDataForm: FormGroup;
  loading = false;
  public editorOptions: JsonEditorOptions;
  public data: any;
  @Input() templates;
  template;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {

    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';
  }
  get f() {
    return this.addDataForm.controls;
  }

  ngOnInit(): void {
    this.template = this.templates[0];
    this.addDataForm = this.formBuilder.group({
      template: [this.template, Validators.required],
      key: ['', Validators.required],
      data: [{}],
    });

  }

  add() {
    this.loading = true;
    const data = {template: this.f.template.value, key: this.f.key.value, data: this.f.data.value};
    this.generalService.postService(new ServiceConfig('esl/template/data', {}, data)).subscribe(rs => {
      this.loading = false;
      this.added.emit(rs);
      this.generalService.notiService('success');
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
