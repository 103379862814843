import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';
import {JsonEditorOptions} from 'ang-jsoneditor';

@Component({
  selector: 'app-edit-esl-template',
  templateUrl: './edit-esl-template.component.html',
})
export class EditEslTemplateComponent implements OnInit {

  @Input() item;
  edited = new EventEmitter();
  editTemplateForm: FormGroup;
  loading = false;
  public editorOptions: JsonEditorOptions;
  public data: any;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';
  }
  get f() {
    return this.editTemplateForm.controls;
  }

  ngOnInit(): void {
    this.editTemplateForm = this.formBuilder.group({
      name: [this.item.name, Validators.required],
      key: [this.item.key, Validators.required],
      schemaModel: [this.item.schemaModel, Validators.required],

    });
  }

  edit() {
    this.loading = true;
    const data = {name: this.f.name.value, key: this.f.key.value, schemaModel: this.f.schemaModel.value};
    this.generalService.putService(new ServiceConfig('esl/template/v2', {id: this.item.id}, data)).subscribe(rs => {
      this.generalService.notiService('success');
      this.loading = false;
      this.edited.emit(rs);
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
