import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';
import {JsonEditorOptions} from 'ang-jsoneditor';

@Component({
  selector: 'app-add-esl-template',
  templateUrl: './add-esl-template.component.html',
})
export class AddEslTemplateComponent implements OnInit {

  added = new EventEmitter();
  addTemplateForm: FormGroup;
  loading = false;
  public editorOptions: JsonEditorOptions;
  public data: any;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';
  }
  get f() {
    return this.addTemplateForm.controls;
  }

  ngOnInit(): void {
    this.addTemplateForm = this.formBuilder.group({
      name: ['', Validators.required],
      key: ['', Validators.required],
      schemaModel: [{}],
    });

  }

  add() {
    this.loading = true;
    const data = {name: this.f.name.value, key: this.f.key.value, schemaModel: this.f.schemaModel.value};
    this.generalService.postService(new ServiceConfig('esl/template/v2', {}, data)).subscribe(rs => {
      this.loading = false;
      this.added.emit(rs);
      this.generalService.notiService('success');
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
