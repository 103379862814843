export type Action = {
    get?: boolean,
    add?: boolean,
    delete?: boolean,
    edit?: boolean,
    download?: boolean
};
export type Menu = {
    key: string,
    iconClass: string,
    active: boolean,
    url: string,
    show: boolean,
    actions: Action,
    submenu: { key: string, url: string, actions?: Action, show: boolean }[]
};
// tslint:disable-next-line:variable-name
export const _Menu: Menu[] = [
    {
        key: 'ESL',
        active: false,
        url: 'home',
        submenu: null,
        actions: {add: false, edit: false, delete: false},
        show: true,
        iconClass: 'fa fa-home',
    },
    {
        key: 'Veri',
        active: false,
        url: 'data',
        submenu: null,
        actions: {add: false, edit: false, delete: false},
        show: false,
        iconClass: 'fas fa-database',
    },
    {
        key: 'Kayıt',
        active: false,
        url: 'log',
        submenu: null,
        actions: null,
        show: true,
        iconClass: 'fas fa-hdd',
    },
    {
        key: 'Tasarım',
        active: false,
        url: 'esl-template',
        submenu: null,
        actions: {add: false, edit: false, delete: false},
        show: false,
        iconClass: 'fas fa-th-large',
    },
    {
        key: 'Konum',
        active: false,
        url: 'location',
        submenu: null,
        actions: {add: false, edit: false, delete: false},
        show: false,
        iconClass: 'fas fa-map-marker-alt',
    },
    {
        key: 'Kullanıcı',
        active: false,
        url: 'user',
        submenu: null,
        actions: {add: false, edit: false, delete: false},
        show: false,
        iconClass: 'fas fa-user',
    },
    {
        key: 'Konfigürasyon',
        active: false,
        url: 'config',
        submenu: null,
        actions: {add: false, edit: false, delete: false},
        show: false,
        iconClass: 'fas fa-cog',
    },
];
