import {AfterViewInit, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';
import {BarcodeScannerLivestreamComponent} from 'ngx-barcode-scanner';

@Component({
  selector: 'app-search-barcode',
  templateUrl: './read-barcode.component.html',
})
export class ReadBarcodeComponent implements OnInit, AfterViewInit {
  searched = new EventEmitter();

  searchBarcodeForm: FormGroup;
  loading = false;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
  }
  get f() {
    return this.searchBarcodeForm.controls;
  }
  ngOnInit(): void {
    this.searchBarcodeForm = this.formBuilder.group({
      barcode: ['', Validators.required],
    });
  }
  add() {
    this.loading = true;
    const service = new ServiceConfig('esl/open/barcode', {b: this.f.barcode.value});
    this.generalService.getService(service).subscribe((rs: any) => {
      this.loading = false;
      this.generalService.notiService('info', 'ESL Aranıyor...', '');
      this.searched.emit(rs.key);
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

  @ViewChild(BarcodeScannerLivestreamComponent)
  barcodeScanner: BarcodeScannerLivestreamComponent;

  barcodeValue;

  ngAfterViewInit() {
    this.barcodeScanner?.start();
  }

  onValueChanges(result) {
    this.barcodeValue = result.codeResult.code;
  }

  onStarted(started) {
    console.log(started);
  }

}
