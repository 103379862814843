import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {LoginComponent} from './auth/login/login.component';
import {AuthGuard, LogoutGuard} from './_helpers';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LogoutGuard],
  },
  {path: '', redirectTo: 'pages/home', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages/home'},
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  entryComponents: [],
})
export class AppRoutingModule {
}
