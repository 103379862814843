<div class="modal-header">
    <h4 *ngIf="!hide" class="modal-title">ESL VERİ GÖNDER</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="sendEslForm" (ngSubmit)="add()">
        <div class="form-group">
            <label>ESL</label>
            <ng-select (change)="changeEsl($event)" [clearable]="false" formControlName="esl" [(ngModel)]="selectedItem.id">
                <ng-option *ngFor="let esl of esls" [value]="esl.id">{{esl.eslNo}}</ng-option>
            </ng-select>
        </div>
        <div class="form-group">
            <label>Tasarım</label>
            <ng-select [clearable]="false" formControlName="templateId" [(ngModel)]="selectedTemplate">
                <ng-option *ngFor="let template of templates" [value]="template.id">{{template.key}}</ng-option>
            </ng-select>
        </div>
<!--        <div class="form-group">
            <label for="formGroupExampleInput2">Template</label>
            <input formControlName="nameData" type="text" class="form-control" id="formGroupExampleInput2"
                   placeholder="Ad Soyad">
        </div>
        <div class="form-group">
            <label for="formGroupExampleInput3">Ünvan</label>
            <input formControlName="titleData" type="text" class="form-control" id="formGroupExampleInput3"
                   placeholder="Ünvan">
        </div>-->

        <div class="modal-footer">
            <button [disabled]="sendEslForm.invalid || loading" type="submit" class="btn btn-primary">
                <span *ngIf="loading">
                   <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Gönderiliyor...
                </span>
                <span *ngIf="!loading">Gönder</span>
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Kapat</button>
        </div>
    </form>
</div>

