import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';
import {JsonEditorOptions} from 'ang-jsoneditor';

@Component({
    selector: 'app-send-esl-item',
    templateUrl: './send-esl-free-item.component.html',
})
export class SendEslFreeItemComponent implements OnInit {

    added = new EventEmitter();
    sendEslForm: FormGroup;
    loading = false;
    @Input() templates;
    @Input() item;
    @Input() esls;
    selectedTemplate;
    selectedItem;
    hide = false;
    public editorOptions: JsonEditorOptions;
    data = [];

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
    }

    get f() {
        return this.sendEslForm.controls;
    }

    ngOnInit(): void {
        const tempId = localStorage.getItem('selectedTemplateId');
        const selectedTemplate = this.templates.find(f => tempId === f.id?.toString()) ?? this.templates[0];
        this.selectedTemplate = selectedTemplate.id;
        this.selectedItem = this.esls.find(esl => esl.id === this.item.id);
        this.sendEslForm = this.formBuilder.group({
            esl: [this.selectedItem.id, Validators.required],
            templateId: [this.selectedTemplate, Validators.required],
        });
        this.data = this.getData(selectedTemplate?.schemaModel);
        console.log(this.selectedTemplate);
        this.editorOptions = new JsonEditorOptions();
        this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptions.mode = 'code';
    }

    getData(schema) {
        const data = [];
        for (const key in schema) {
            if (schema.hasOwnProperty(key)) {
                data.push({key, value: schema[key], data: ''});
            }
        }
        return data;
    }

    getResultData() {
        let data = {};
        for (const dt of this.data) {
            data = {...data, [dt.key]: dt.data};
        }
        return data;
    }

    changeEsl(id) {
        this.selectedItem = this.esls.find(esl => esl.id === id);
        this.hide = true;
        setTimeout(() => {
            this.hide = false;
        });
    }

    designChanged(item) {
        const design = this.templates.find(f => item.toString() === f.id?.toString());
        if (design) {
            this.data = this.getData(design?.schemaModel);
        }
    }

    add() {
        this.loading = true;
        const data = {eslId: this.selectedItem.id, templateId: this.selectedTemplate, data: this.getResultData()};
        this.generalService.postService(new ServiceConfig('esl/bind/v3', {}, data)).subscribe((rs: any) => {
            this.loading = false;
            this.added.emit(rs.esl);
            localStorage.setItem('selectedTemplateId', this.selectedTemplate?.toString());
            this.generalService.notiService('success');
        }, error => {
            this.loading = false;
            this.generalService.notiService('error');
        });
    }

}
