<div class="container">
    <div class="row">
        <div class="col-lg-3 col-md-2"></div>
        <div class="col-lg-6 col-md-8 login-box">
            <div class="col-lg-12 login-key">
                <i class="fa fa-key" aria-hidden="true"></i>
            </div>
            <div class="col-lg-12 login-title">
                ESL ADMIN
            </div>

            <div class="col-lg-12 login-form">
                <div class="col-lg-12 login-form">
                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                        <div class="form-group">
                            <label class="form-control-label">Kullanıcı Adı</label>
                            <input type="text" class="form-control" formControlName="username">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">ŞİFRE</label>
                            <input type="password" class="form-control" formControlName="password">
                        </div>

                        <div class="col-lg-12 loginbttm">
                            <div class="login-btm login-button">
                                <button type="submit" class="btn btn-outline-primary">GİRİŞ YAP</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-3 col-md-2"></div>
        </div>
    </div>
</div>
