<nav class="navbar navbar-dark bg-dark fixed-top ">
    <div style="max-width: 800px" class="d-block m-auto w-100">
        <a class="navbar-brand d-flex">
            <div class="d-md-flex d-none">
                <input style="min-width: 160px" (input)="filterChanged()" [(ngModel)]="filter.n"
                       autocomplete="off" class="form-control mr-sm-2 float-left" type="search"
                       placeholder="No">
                <input style="min-width: 160px" (input)="filterChanged()" [(ngModel)]="filter.m"
                       autocomplete="off" class="form-control mr-sm-2 float-left" type="search"
                       placeholder="Mac">
<!--                <input style="min-width: 160px" (ngModelChange)="filterChanged()" [(ngModel)]="filter.l"
                       autocomplete="off" class="form-control mr-sm-2 float-left" type="search"
                       placeholder="Konum">-->
<!--                <ng-select placeholder="Tasarım Ara" (change)="filterChanged()" style="min-width: 160px" [clearable]="true" [(ngModel)]="filter.l">
                    <ng-option *ngFor="let template of templates" [value]="template.id">{{template.key}}</ng-option>
                </ng-select>-->
            </div>
            <app-action class="mx-1" (add)="add()" type="add" name="ESL Ekle"></app-action>
            <button (click)="searchBarcode()" class="btn btn-danger mx-1">Barkod Ara</button>
<!--            <button (click)="readBarcode()" class="btn btn-warning mx-1">Barkod Tara</button>-->

        </a>
    </div>
</nav>
<div style="margin-top: 70px" class="table-responsive">
    <div class="d-md-none d-flex">
        <input [(ngModel)]="filter.n" autocomplete="off" class="form-control m-2 float-left" type="search"
               placeholder="No">
        <input [(ngModel)]="filter.m" autocomplete="off" class="form-control m-2 float-left" type="search"
               placeholder="Mac">
<!--        <input [(ngModel)]="filter.l" autocomplete="off" class="form-control m-2 float-left" type="search"
               placeholder="Konum">-->
    </div>
    <table class="table table-striped">
        <thead>
        <tr>
            <th scope="col">No</th>
            <th scope="col">Mac</th>
            <th scope="col">Konum</th>
            <th scope="col">Veri</th>

            <!--            <th scope="col">İsim</th>
                        <th scope="col">Ünvan</th>-->
            <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of items; index as i; trackBy: trackByFn">
            <td data-label="No" (click)="send(item)">{{item.eslNo}}</td>
            <td data-label="Mac" (click)="send(item)">{{item.eslId}}</td>
            <td data-label="Konum" (click)="send(item)">{{item.location?.name}}</td>
            <td data-label="Veri" (click)="send(item)">{{item.data?.dataModel?.key}}</td>

            <!--            <td data-label="İsim" >{{item.data?.name}}</td>
                        <td data-label="Ünvan" >{{item.data?.title}}</td>-->
            <td data-label="">
                <a (click)="send(item)"><i class="mx-2 fas fa-paper-plane"></i></a>
                <a (click)="sendFree(item)"><i class="mx-2 fas fa-plus-circle"></i></a>
                <app-action (delete)="delete(item)" (edit)="edit(item)"></app-action>
            </td>
        </tr>
        </tbody>
    </table>
    <div *ngIf="loading" class="table-loading">
        <div class="loading-content">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
<div  class="justify-content-center d-flex mb-4">
    <ngb-pagination *ngIf="!loading"  (pageChange)="pageChanged($event)" [maxSize]="5" [(page)]="page"
                    [pageSize]="pageSize" [collectionSize]="total"></ngb-pagination>
</div>
