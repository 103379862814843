<div class="modal-header">
    <h4 class="modal-title">Kullanıcı Ekle</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="addUserForm" (ngSubmit)="add()">
        <div class="form-group">
            <label for="formGroupExampleInput">Ad Soyad</label>
            <input formControlName="name" type="text" class="form-control" id="formGroupExampleInput"
                   placeholder="Ad Soyad">
        </div>
        <div class="form-group">
            <label for="formGroupExampleInput2">Kullanıcı Adı</label>
            <input formControlName="username" type="text" class="form-control" id="formGroupExampleInput2"
                   placeholder="Kullanıcı Adı">
        </div>
        <div class="form-group">
            <label for="formGroupExampleInput3">Şifre</label>
            <input formControlName="password" type="text" class="form-control" id="formGroupExampleInput3"
                   placeholder="Şifre">
        </div>
        <div class="modal-footer">
            <button [disabled]="addUserForm.invalid || loading" type="submit" class="btn btn-primary">
                <span *ngIf="loading">
                   <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Gönderiliyor...
                </span>
                <span *ngIf="!loading">Kullanıcı Ekle</span>
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Kapat</button>
        </div>
    </form>
</div>
