<nav class="navbar navbar-dark bg-dark fixed-top ">
    <div style="max-width: 600px" class="d-block m-auto w-100">
        <a class="navbar-brand d-flex  w-100 justify-content-center">
            <input [(ngModel)]="search" autocomplete="off" class="form-control mr-sm-2 float-left" type="search" placeholder="Kullanıcı Ara">
            <app-action (add)="add()" type="add" name="Kullanıcı Ekle"></app-action>
        </a>
    </div>
</nav>
<div style="margin-top: 70px" class="table-responsive">
    <table class="table table-striped">
        <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Kullanıcı Adı</th>
            <th scope="col">Ad Soyad</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of items | filter: 'fullName': search; index as i; trackBy: trackByFn">
            <td data-label="#">{{i + 1}}</td>
            <td data-label="Kullanıcı Adı">{{item.userName}}</td>
            <td data-label="Ad Soyad">{{item.fullName}}</td>
            <td data-label="">
                <app-action (delete)="delete(item)" (edit)="edit(item)"></app-action>
            </td>
        </tr>
        </tbody>
    </table>
    <div *ngIf="loading" class="table-loading">
        <div class="loading-content">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
