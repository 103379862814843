import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GeneralService} from '../../../services/general.service';
import {ServiceConfig} from '../../../shared/ServiceConfig';
import {JsonEditorOptions} from 'ang-jsoneditor';

@Component({
  selector: 'app-edit-data',
  templateUrl: './edit-data.component.html',
})
export class EditDataComponent implements OnInit {

  @Input() item;
  @Input() templates;
  edited = new EventEmitter();
  editDataForm: FormGroup;
  loading = false;
  public editorOptions: JsonEditorOptions;
  public data: any;
  template;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private generalService: GeneralService) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';
  }
  get f() {
    return this.editDataForm.controls;
  }

  ngOnInit(): void {
    this.editDataForm = this.formBuilder.group({
      template: [this.item.template, Validators.required],
      key: [this.item.key, Validators.required],
      data: [this.item.data],
    });
  }

  edit() {
    this.loading = true;
    const data = {template: this.f.template.value, key: this.f.key.value, data: this.f.data.value};
    this.generalService.putService(new ServiceConfig('esl/template/data', {id: this.item.id}, data)).subscribe(rs => {
      this.generalService.notiService('success');
      this.loading = false;
      this.edited.emit(rs);
    }, error => {
      this.loading = false;
      this.generalService.notiService('error');
    });
  }

}
