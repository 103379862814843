import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {map} from 'rxjs/operators';
import {EnvironmentService} from '../../services/environment.service';


@Injectable()
export class AuthenticationService {
    private authSubject: BehaviorSubject<any>;
    public auth: Observable<any>;
    private userSubject: BehaviorSubject<any>;
    public user: Observable<any>;
    constructor(private http: HttpClient, private envService: EnvironmentService) {
        this.authSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('eslAdminAuth')));
        this.auth = this.authSubject.asObservable();
        this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('eslAdminUser')));
        this.user = this.userSubject.asObservable();
    }
    public get authValue() {
        return this.authSubject.value;
    }
    setAuth(auth): void {
        this.authSubject.next(auth);
    }
    setUser(user): void {
        this.userSubject.next(user);
    }
    isAdmin() {
       return this.userValue?.authorities?.find(a => a?.authority === 'ROLE_ADMIN') !== undefined;
    }
    public get userValue() {
        return this.userSubject.value;
    }

    login(username: string, pasw: string) {
        const auth = 'Basic ' + btoa(username + ':' + pasw);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: auth,
            })
        };
        return this.http.get<any>(this.envService.getUrl() + 'auth', httpOptions)
            .pipe(map((user) => {
                localStorage.setItem('eslAdminAuth', JSON.stringify(auth));
                this.authSubject.next(auth);
                localStorage.setItem('eslAdminUser', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    logout() {
        localStorage.removeItem('eslAdminAuth');
        this.authSubject.next(null);
        localStorage.removeItem('eslAdminUser');
        this.userSubject.next(null);
    }
}
